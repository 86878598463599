







































































import {Component, Vue} from 'vue-property-decorator';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component
export default class StartGamePage extends Vue {
  private gameCode: string | null = null;

  joinGame() {
    if (this.gameCode) {
      this.$router.push({name: 'play-game', params: {id: this.gameCode}})
    }
  }
}
