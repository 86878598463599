import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import SharedPageWrapper from '@/views/SharedPageWrapper.vue';
import StartGamePage from "@/views/StartGamePage.vue";
import PlayGamePage from "@/views/PlayGamePage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
    name: 'shared-page-wrapper',
    component: SharedPageWrapper,
    meta: { requiresAuthentication: true },
    children: [
      {
        path: '/',
        name: 'start-game',
        component: StartGamePage,
        meta: { isInGamePage: false },
      },
      {
        path: '/game/:id',
        name: 'play-game',
        component: PlayGamePage,
        meta: { isInGamePage: true },
      },
      {
        path: '*',
        redirect: '/'
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   let isAuthenticated = authStore.getIsAuthenticated;
//   const authStateReady = authStore.getAuthStateReady;
//   const requiresAuthentication = to.matched.some((match) => match.meta.requiresAuthentication);
//
//   if (!isAuthenticated && !authStateReady) {
//     try {
//       const authResponse = await AuthService.getUser();
//       const companyResponse = await CompanyService.getCompanySettings();
//       const profileResponse = await ProfileService.getUserProfile();
//
//       if (authResponse.status === 200 && companyResponse.status === 200 && profileResponse.status === 200) {
//         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//         // @ts-ignore
//         authStore.setUser(authResponse.data.data);
//         companyStore.setUserCompany(companyResponse.data.data);
//         profileStore.setUserProfile(profileResponse.data.data);
//
//         isAuthenticated = true;
//       }
//     } catch (error) {
//       console.log(error.data);
//     } finally {
//       authStore.setAuthStateReady();
//     }
//   }
//
//   if (requiresAuthentication && !isAuthenticated) {
//     AuthService.redirectToLoginPage();
//
//     return;
//   }
//
//   if (!requiresAuthentication && isAuthenticated) {
//     next({ name: 'dashboard' });
//
//     return;
//   }
//
//   next();
// });

export default router;
