






import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  public created(): void {
    document.title = process.env.VUE_APP_TITLE;
  }
}
