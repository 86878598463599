






import {Component, Vue} from 'vue-property-decorator';
import * as L from 'leaflet'
import {LatLngTuple} from 'leaflet'

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component
export default class PlayGamePage extends Vue {
  private startLat = 52.38140516334218;
  private startLong = 4.6360158920288095;
  private startLatLong: LatLngTuple = [this.startLat, this.startLong];
  private map: L.Map | null = null;
  private marker: L.Marker | null = null;
  private circle: L.Circle | null = null;
  private spoofMode = false;

  mounted() {
    this.map = L.map('map').setView(this.startLatLong, 13);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoidGVjaGJpdGUiLCJhIjoiY2p2d2NmNTg1MDcydzN5cng3YjR2cDN4ZCJ9.IfpE0puffoMabkjDb4UffA'
    }).addTo(this.map);

    // map.locate({setView: true, maxZoom: 16});

    if ('geolocation' in navigator) {
      navigator.geolocation.watchPosition((data) => {
        if (!this.spoofMode) {
          this.updateMarker(data.coords.latitude, data.coords.longitude, data.coords.accuracy);
        }
      });
    }

    this.map.on('click', (e: any) => {
      if (this.spoofMode) {
        this.updateMarker(e.latlng.lat, e.latlng.lng, 10);
      }
    });

    this.goToStart();
  }

  updateMarker(lat: number, long: number, radius: number) {
    console.log(`Location: Lat: ${lat}, Long: ${long}, Accuracy: ${radius}`);

    const latLong: LatLngTuple = [lat, long];

    if (this.marker) {
      this.marker.remove();
      this.marker = null;
    }

    if (this.circle) {
      this.circle.remove();
      this.circle = null;
    }

    const icon = L.icon({
      iconUrl: require('../assets/images/marker-icon.png'),
      iconSize: [24, 41],
      iconAnchor: [12, 41],
      popupAnchor: [-0, -50 ],
      // shadowUrl: require('../assets/images/marker-shadow.png'),
      // shadowSize: [68, 95],
      // shadowAnchor: [22, 94]
    });

    this.marker = L.marker(latLong, {icon}).addTo(this.map!)
      .bindPopup("You are within " + radius + " meters from this point")
      .openPopup();

    this.circle = L.circle(latLong, radius).addTo(this.map!);

    this.map!.setView(latLong, 18);
  }

  goToStart() {
    this.updateMarker(this.startLat, this.startLong, 10);
  }
}
