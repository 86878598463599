import Vue from 'vue';
// @ts-ignore
import vSelect from 'vue-select';
// @ts-ignore
import VTooltip from 'v-tooltip';
import App from './App.vue';
import router from './router';
import './assets/index.css';
import 'v-tooltip/dist/v-tooltip.css';
import 'leaflet/dist/leaflet.css';
// declare module 'vue/types/vue' {
//   interface Vue {
//     $confetti: any;
//   }
//
//   interface VueConstructor {
//     $confetti: any;
//   }
// }

Vue.config.productionTip = false;

// Vue.use(Notifications);
Vue.use(VTooltip);
Vue.component('v-select', vSelect);

// axios.defaults.withCredentials = true;

// let loaderTimeOut: number | null = null;

// // When data fetching has started, set the loading state to true
// axios.interceptors.request.use((config) => {
//   loaderTimeOut = setTimeout(() => {
//     loadingStore.setLoading(true);
//   }, 150);
//
//   return config;
// });
//
// // When data fetching is done, set the loading state to false
// axios.interceptors.response.use((response): AxiosResponse => {
//   if (loaderTimeOut) {
//     clearTimeout(loaderTimeOut);
//     loaderTimeOut = null;
//   }
//
//   loadingStore.setLoading(false);
//
//   return response;
// });
//
// // Check for 401 or 419 response status codes
// axios.interceptors.response.use((response): AxiosResponse => response, (error) => {
//   if (loaderTimeOut) {
//     clearTimeout(loaderTimeOut);
//     loaderTimeOut = null;
//   }
//
//   loadingStore.setLoading(false);
//
//   if (error.response.status === 401 || error.response.status === 419) {
//     AuthService.redirectToLoginPage();
//   }
//
//   return Promise.reject(error);
// });

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
